<template>
    <div class="container">
        <Header page="home" />
        <div class="box-wrapper">
            <div class="d-flex box-list">
                <div class="col"><h2>{{$t('drug_name')}}</h2> <span class="box-label cursor-pointer" @click="name.push('')"><font-awesome-icon icon="plus" /></span></div>
                <div><span :class="name.length == 1 && name[0] == '' ? 'text-danger' : 'text-info'">{{ name.length == 1 && name[0] == "" ? "Not selected" : `${name.length} selected` }}</span></div>
            </div>
            <div class="box-bg-gray" >
                <div v-for="(val, key) in name" class="box-bg-white " :key="key">
                    <div>
                        <Autocomplete :items="list_name" inputname="name" v-model="name[key]"  :placeholder="$t('add')+' '+$t('drug_name')" classinputname="form-control-sm w-100" :isLoading="autocomplete_load"/>    
                    </div>
                    <div v-if="key!==0"  @click="name.splice(key,  1)"><span class="icon-trash cursor-pointer" ><font-awesome-icon icon="trash" :style="{color : '#f15858', fontSize:'13px'}" /></span>
                    </div>
                </div>
            </div>
        </div>

        <div class="box-wrapper">
            <div class="d-flex box-list">
                <div class="col"><h2>{{$t('dci')}}</h2> <span class="box-label cursor-pointer" @click="dci.push('')"><font-awesome-icon icon="plus" /></span></div>
                <div><span :class="dci.length == 1 && dci[0] == '' ? 'text-danger' : 'text-info'">{{ dci.length == 1 && dci[0] == "" ? "Not selected" : `${dci.length} selected` }}</span></div>
            </div>
            <div class="box-bg-gray" >
                <div v-for="(val, key) in dci" class="box-bg-white " :key="key">
                    <div>
                        <Autocomplete :items="list_dci" inputname="dci" v-model="dci[key]"  :placeholder="$t('add')+' '+$t('dci')" classinputname="form-control-sm w-100" :isLoading="autocomplete_load"/>    
                    </div>
                    <div v-if="key!==0"  @click="dci.splice(key,  1)"><span class="icon-trash cursor-pointer" ><font-awesome-icon icon="trash" :style="{color : '#f15858', fontSize:'13px'}" /></span>
                    </div>
                </div>
            </div>
        </div>

        <div class="box-wrapper">
            <div class="d-flex box-list">
                <div class="col"><h2>{{$t('therapeutic')}}</h2> <span class="box-label cursor-pointer" data-bs-toggle="modal" data-bs-target="#Modal-therapeutic" style="padding:1px 12px">List</span> <span class="box-label cursor-pointer" @click="therapeutic.push('')" style="margin-left:64px"><font-awesome-icon icon="plus" /></span></div>
                <div><span :class="therapeutic.length == 1 && therapeutic[0] == '' ? 'text-danger' : 'text-info'">{{ therapeutic.length == 1 && therapeutic[0] == "" ? "Not selected" : `${therapeutic.length} selected` }}</span></div>
            </div>
            <div class="box-bg-gray" >
                <div v-for="(val, key) in therapeutic" class="box-bg-white " :key="key">
                    <div>
                        <Autocomplete :items="list_therapeutic" inputname="therapeutic" v-model="therapeutic[key]"  :placeholder="$t('add')+' '+$t('therapeutic')" classinputname="form-control-sm w-100" :isLoading="autocomplete_load"/>    
                    </div>
                    <div v-if="key!==0"  @click="therapeutic.splice(key,  1)"><span class="icon-trash cursor-pointer" ><font-awesome-icon icon="trash" :style="{color : '#f15858', fontSize:'13px'}" /></span>
                    </div>
                </div>
            </div>
        </div>
        <ModalTherapeutic :items="list_therapeutic" @save_item_popup="save_item_popup" :isLoading="autocomplete_load"/>

        <div class="box-wrapper">
            <div class="d-flex box-list">
                <div class="col"><h2>Agency</h2> <span class="box-label cursor-pointer" @click="handleAllAgency(true)" style="padding:1px 12px">All</span> <span class="box-label cursor-pointer" style="padding:1px 12px;margin-left:60px" @click="handleAllAgency(false)">Clear</span>
                </div>
                <div><span :class="getSelectAgency.length === 0 ? 'text-danger' : 'text-info'">{{ getSelectAgency.length === 0 ? "Not selected" : `${getSelectAgency.length} selected` }}</span></div>
            </div>
            <div class="box-bg-gray wrap-box-flag">   
                <template v-if="agency.length">             
                <div  v-for="(item,key) in agency" :key="key" class="box-list-flag" @click="agency[key]['state'] = !agency[key]['state']">
                    <div class="list-child cursor-pointer">
                        <div class="flag-cover" :class="{ active: item.state}"><em class="flag sizemd" :class="item.icon_flags"></em></div>                        
                        <p>{{item.acronym}}</p>
                        <p>({{item.alpha_3_code}})</p>
                    </div>
                </div> 
                </template> 
                <div v-else style="height:67px">
                    <div class="overlay">
                        <span class="multiselect-spinner" style="top: 37%;left: 49%;"></span>
                    </div> 
                </div>      
            </div>
        </div>
        <div class="text-center">
            <button class="btn btn-sea-blue btn-sm" @click="handleSearch(false)">Search <font-awesome-icon icon="chevron-right" :style="{ marginLeft: '3px' }" /></button>
        </div>
    </div>
    
</template>

<script>
import Header from '@/components/Layout/Header.vue' 
import ModalTherapeutic from '@/components/elements/ModalTherapeutic.vue'  
import Autocomplete from '@/components/elements/Autocomplete.vue'
import useFormSearch from "@/hooks/useFormSearch.js"
export default {
    components:{
        Header,
        ModalTherapeutic,
        Autocomplete
    },
    setup(){

        const {name,
        therapeutic,
        dci,
        list_name, 
        list_therapeutic, 
        list_dci, 
        autocomplete_load, 
        agency, 
        getSelectAgency,
        save_item_popup,
        handleAllAgency,
        handleSearch} = useFormSearch.useFormSearch();        

        return { 
            name,
            therapeutic,
            dci,
            list_name, 
            list_therapeutic, 
            list_dci, 
            autocomplete_load, 
            agency, 
            getSelectAgency,
            save_item_popup,
            handleAllAgency,
            handleSearch
        }
    },    
}
</script>
